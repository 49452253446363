<template>
  <div class="elv-result">
    <img :src="props.imgSrc" />
    <div class="elv-result-description">
      <p v-if="props.description">{{ props.description }}</p>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import noDataImg from '@/assets/img/noData.png'

const props = defineProps({
  imgSrc: {
    type: String,
    default() {
      return noDataImg
    }
  },
  description: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '40px'
  },
  height: {
    type: String,
    default: '40px'
  },
  fontSize: {
    type: String,
    default: '12px'
  },
  fontColor: {
    type: String,
    default: '#9fa1a5'
  }
})
</script>

<style scoped lang="scss">
.elv-result {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 96px;

  img {
    display: block;
    width: v-bind('props.width');
    height: v-bind('props.height');
  }

  .elv-result-description {
    margin-top: 16px;
    width: 100%;
    text-align: center;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: v-bind('props.fontSize');
    color: v-bind('props.fontColor');
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
